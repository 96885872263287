import React from "react";
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import "./Patrocinadors.css";
import logo1 from "../../../assets/logoCellnex.png";
import logo2 from "../../../assets/CIB.png";
import logo3 from "../../../assets/auren-logo.png";
const Patrocinadors = () => {
  return (
    <div className="patrocinadors__container" id="patrocinadors">
      <SectionHeader title={"Patrocinadors"} />
      <div className="patrocinadors__item-wrapper">
        <a href="https://auren.com/es/" target="_blank" rel="noreferrer">
          <img alt="patrocini" src={logo3} className="patrocinadors__item" />
        </a>
        <a href="https://www.cellnex.com/es/" target="_blank" rel="noreferrer">
          <img alt="patrocini" src={logo1} className="patrocinadors__item" />
        </a>
        <a
          href="https://cib.partners/ca/inici/"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="patrocini" src={logo2} className="patrocinadors__item" />
        </a>
      </div>
    </div>
  );
};

export default Patrocinadors;
